import { EventBus } from '../EventBus';
import { Scene } from 'phaser';

export class StartScreen extends Scene {
  logoTween;

  constructor() {
    super('StartScreen');
  }
  init() {
    this.checkIfConnected = false;
  }

  create() {
    let { width, height } = this.sys.game.canvas;
    this.add.image(width / 2, height / 2, 'start_bg');

    // CONNECT WALLET

    this.connect = this.add
      .image(width / 2, height / 2, 'connect')
      .setInteractive({ useHandCursor: true })
      .setDepth(999);
    this.connect.on('pointerover', () =>
      this.connect.setTexture('connect_hover')
    );
    this.connect.on('pointerout', () => this.connect.setTexture('connect'));
    this.connect.on('pointerup', () => {
      EventBus.emit('connect-wallet', this);
      this.connect.setTexture('connect_hover');
    });

    // PLAY BUTTON
    this.play = this.add
      .image(width / 2, height / 2, 'run')
      .setInteractive({ useHandCursor: true })
      .setDepth(999)
      .setVisible(false);
    this.play.on('pointerover', () => this.play.setTexture('run_hover'));
    this.play.on('pointerout', () => this.play.setTexture('run'));
    this.play.on('pointerup', () => {
      this.play.setTexture('run_hover');
      this.scene.start('MainMenu');
    });

    // ADDRESS
    this.address = this.add.text(width - 100, 20, '', {
      fontFamily: 'Arial Black',
      fontSize: 12,
      color: '#ffffff',
      stroke: '#000000',
      strokeThickness: 8,
      align: 'center',
    });

    // LEADERBOARD
    this.leaderboard = this.add
      .image(width / 2, height / 2 + 60, 'leaderboard')
      .setInteractive({ useHandCursor: true })
      .setDepth(999);
    this.leaderboard.on('pointerover', () =>
      this.leaderboard.setTexture('leaderboard_hover')
    );
    this.leaderboard.on('pointerout', () =>
      this.leaderboard.setTexture('leaderboard')
    );
    this.leaderboard.on('pointerup', () => {
      EventBus.emit('open-leaderboard', this);
      this.leaderboard.setTexture('leaderboard_hover');
      this.scene.start('Leaderboard');
    });

    EventBus.emit('current-scene-ready', this);
  }

  update() {
    if (!this.checkIfConnected && window.solana.isConnected) {
      console.log('CONNECTED FROM SCENE');
      this.checkIfConnected = true;
      this.address.setText(
        `${
          window.solana.publicKey.toBase58().slice(0, 4) +
          '..' +
          window.solana.publicKey.toBase58().slice(-4)
        }`
      );
      this.play.setVisible(true);
      this.connect.setVisible(false);
    }
  }
}
