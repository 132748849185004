import { Boot } from './scenes/Boot';
import { Game } from './scenes/Game';
import { GameOver } from './scenes/GameOver';
import { MainMenu } from './scenes/MainMenu';
import { Level1 } from './scenes/Level1';
import { StartScreen } from './scenes/StartScreen';
import { Leaderboard } from './scenes/Leaderboard';

import Phaser from 'phaser';
import { Preloader } from './scenes/Preloader';

// Find out more information about the Game Config at:
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config = {
  type: Phaser.AUTO,
  width: 1080,
  height: 801,
  parent: 'game-container',
  dom: {
    createContainer: true,
  },
  backgroundColor: '#028af8',
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 1100 },
      debug: false,
    },
  },
  input: {
    activePointers: 1, // Number of pointers to track
    useHandCursor: true, // Enable hand cursor
  },
  scene: [
    Boot,
    Preloader,
    MainMenu,
    Game,
    GameOver,
    Level1,
    StartScreen,
    Leaderboard,
  ],
};

const StartGame = (parent) => {
  return new Phaser.Game({ ...config, parent });
};

export default StartGame;
