import React, { useState, useEffect, useRef, useCallback } from 'react';
import Phaser from 'phaser';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';

import './runner.css';
import { PhaserGame } from './game/PhaserGame';
import bg from './assets/bg-static.png';

import { EventBus } from './game/EventBus';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { doc, setDoc, getDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCZYp2r1pqNr6T5zCSJoxLz1XqorFO_WHU',
  authDomain: 'dinorunner-18c03.firebaseapp.com',
  projectId: 'dinorunner-18c03',
  storageBucket: 'dinorunner-18c03.appspot.com',
  messagingSenderId: '542105367261',
  appId: '1:542105367261:web:bb1fe74163ed47a76ea134',
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

function Runner() {
  const { setVisible } = useWalletModal();

  const phaserRef = useRef();

  useEffect(() => {
    console.log(window?.solana);
    EventBus.on('connect-wallet', () => {
      setVisible(true);
    });
    EventBus.on('game-over', saveScoreInDb);

    return () => {
      EventBus.removeListener('connect-wallet');
    };
  }, []);

  function saveScoreInDb(data) {
    console.log(data);
    const docRef = doc(db, 'leaderboard', window.solana.publicKey.toBase58());
    var lastScore = 0;
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        var { address, dino, score, playTime } = docSnap.data();
        lastScore = score;
        if (parseInt(data.score) > parseInt(score)) {
          setDoc(doc(db, 'leaderboard', window.solana.publicKey.toBase58()), {
            playTime: data.time,
            score: data.score,
            dino: data.dino,
          })
            .then(() => {
              console.log('Document successfully written!');
            })
            .catch((error) => {
              console.error('Error writing document: ', error);
            });
        } else {
          return false;
        }
      } else {
        setDoc(doc(db, 'leaderboard', window.solana.publicKey.toBase58()), {
          playTime: data.time,
          score: data.score,
          dino: data.dino,
        })
          .then(() => {
            console.log('Document successfully written!');
          })
          .catch((error) => {
            console.error('Error writing document: ', error);
          });
      }
    });
  }

  return (
    <>
      {/* <img className="bg-image" src={bg}></img> */}
      <div id="app" className="game-container">
        <PhaserGame ref={phaserRef} />

        {/* <div>
        <div>
          <button className="button" onClick={changeScene}>
            Change Scene
          </button>
        </div>
        <div>
          <button
            disabled={canMoveSprite}
            className="button"
            onClick={moveSprite}
          >
            Toggle Movement
          </button>
        </div>
        <div className="spritePosition">
          Sprite Position:
          <pre>{`{\n  x: ${spritePosition.x}\n  y: ${spritePosition.y}\n}`}</pre>
        </div>
        <div>
          <button className="button" onClick={addSprite}>
            Add New Sprite
          </button>
        </div>
      </div> */}
      </div>
    </>
  );
}

export default Runner;
