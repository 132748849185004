import { EventBus } from '../EventBus';
import { Scene } from 'phaser';

export class GameOver extends Scene {
  constructor() {
    super('GameOver');
  }
  init(data) {
    this.totalScore = data.score;
    this.playTime = data.time
      .replace('h', ':')
      .replace('m', ':')
      .replace('s', '');
    this.dino = data.dino;

    this.font1 = {
      fontFamily: 'Verdana black',
      fontSize: 16,
      color: '#ffffff',
      align: 'center',
    };
  }
  create() {
    let { width, height } = this.sys.game.canvas;

    this.add.image(width / 2, height / 2, 'game_over_bg');

    EventBus.emit('current-scene-ready', this);

    this.run_again = this.add
      .image(width / 2, height / 2 + 180, 'run_again')
      .setInteractive({ useHandCursor: true });
    this.run_again.on('pointerover', () =>
      this.run_again.setTexture('run_again_hover')
    );
    this.run_again.on('pointerout', () =>
      this.run_again.setTexture('run_again')
    );
    this.run_again.on('pointerup', () => {
      var lvl1 = this.scene.get('Level1');
      lvl1.scene.restart({ dino: this.dino });
      console.log(this.dino);
      this.scene.start('StartScreen');
      this.run_again.setTexture('run_again_hover');
    });

    this.leaderboard = this.add
      .image(width / 2, height / 2 + 225, 'go_leaderboard')
      .setInteractive({ useHandCursor: true });
    this.leaderboard.on('pointerover', () =>
      this.leaderboard.setTexture('go_leaderboard_hover')
    );
    this.leaderboard.on('pointerout', () =>
      this.leaderboard.setTexture('go_leaderboard')
    );
    this.leaderboard.on('pointerup', () => {
      // var lvl1 = this.scene.get('Level1');
      // lvl1.scene.restart();
      // this.scene.start('Level1', { dino: this.dino });

      this.leaderboard.setTexture('go_leaderboard_hover');
      this.scene.start('Leaderboard');
    });

    // dino
    this.add.text(260, 203, 'DINO', this.font1).setOrigin(0);
    this.add
      .text(
        315,
        203,
        this.dino === 'default' ? 'NORM' : this.dino.toUpperCase(),
        this.generateDinoFont()
      )
      .setOrigin(0);
    // time
    this.add.text(400, 203, 'TIME', this.font1).setOrigin(0);
    this.add.text(450, 203, this.playTime, this.font1);
    //score
    this.add.text(570, 203, 'SCORE', this.font1);
    this.add.text(635, 203, this.totalScore, this.font1);
    //rank
    this.add.text(710, 203, 'RANK', this.font1);
    this.add.text(765, 203, 99999, this.font1);
  }

  generateDinoFont() {
    var font = {
      fontFamily: 'Verdana black',
      fontSize: 16,
      color: '#ffffff',
      align: 'center',
    };
    if (this.dino === 'default') {
      font.color = '#8ab654';
    }
    if (this.dino === 'tank') {
      font.color = '#828ac8';
    }
    if (this.dino === 'runner') {
      font.color = '#c8828d';
    }
    return font;
  }
}
