import { EventBus } from '../EventBus';
import { Scene } from 'phaser';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '../../Runner';

export class Leaderboard extends Scene {
  logoTween;

  constructor() {
    super('Leaderboard');
  }
  init(data) {
    this.font = {
      fontFamily: 'Verdana black',
      fontSize: 16,
      color: '#ffffff',
      align: 'center',
    };
    this.address = window.solana.publicKey.toBase58();
    this.results = [];
    this.leaderboardElements = [];
  }

  getLeaderboardData() {
    // clear old data
    this.leaderboardElements.forEach((element) => {
      element.destroy();
    });
    var dinoColors = {
      norm: '#8ab654',
      tank: '#828ac8',
      runner: '#c8828d',
    };
    var scores = [];
    getDocs(collection(db, 'leaderboard'))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          scores.push({ address: doc.id, ...doc.data() });
        });
      })
      .then(() => {
        scores.sort((a, b) => {
          return parseInt(b.score) - parseInt(a.score);
        });
        var c = 0;
        var yGap = 50;

        scores.slice(0, 10).forEach((doc) => {
          var { address, dino, score, playTime } = doc;
          if (dino === 'default') {
            dino = 'norm';
          }
          this.leaderboardElements.push(
            this.add
              .text(
                255,
                160 + yGap * c,
                `${address.slice(0, 4) + '..' + address.slice(-4)}`,
                this.font
              )
              .setOrigin(0.5)
          );
          // dino
          this.leaderboardElements.push(
            this.add
              .text(450, 160 + yGap * c, dino.toUpperCase(), {
                ...this.font,
                color: dinoColors[dino],
              })
              .setOrigin(0.5)
          );
          // time
          this.leaderboardElements.push(
            this.add
              .text(640, 160 + yGap * c, playTime, this.font)
              .setOrigin(0.5)
          );
          // score
          this.leaderboardElements.push(
            this.add.text(830, 160 + yGap * c, score, this.font).setOrigin(0.5)
          );

          c += 1;
        });
      });
  }
  leaderboardListener() {
    const q = query(collection(db, 'leaderboard'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          console.log('New entry: ', change.doc.data());
          this.getLeaderboardData();
          this.getCurrentPlayerScore();
        }
        if (change.type === 'modified') {
          console.log('Modified entry: ', change.doc.data());
          this.getLeaderboardData();
          this.getCurrentPlayerScore();
        }
      });
    });
  }

  getCurrentPlayerScore() {
    var dinoColors = {
      norm: '#8ab654',
      tank: '#828ac8',
      runner: '#c8828d',
    };
    let { width, height } = this.sys.game.canvas;
    const docRef = doc(db, 'leaderboard', this.address);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        var { address, dino, score, playTime } = docSnap.data();
        if (dino === 'default') {
          dino = 'norm';
        }
        this.leaderboardElements.push(
          this.add
            .text(
              255,
              height - 90,
              `${this.address.slice(0, 4) + '..' + this.address.slice(-4)}`,
              this.font
            )
            .setOrigin(0.5)
        );
        // dino
        this.leaderboardElements.push(
          this.add
            .text(450, height - 90, dino.toUpperCase(), {
              ...this.font,
              color: dinoColors[dino],
            })
            .setOrigin(0.5)
        );
        // time
        this.leaderboardElements.push(
          this.add.text(640, height - 90, playTime, this.font).setOrigin(0.5)
        );
        // score
        this.leaderboardElements.push(
          this.add.text(830, height - 90, score, this.font).setOrigin(0.5)
        );
      }
    });
  }

  create() {
    // this.getLeaderboardData();
    this.leaderboardListener();
    // this.getCurrentPlayerScore();
    let { width, height } = this.sys.game.canvas;
    this.add.image(width / 2, height / 2, 'leaderboard_bg');

    this.close = this.add
      .image(width - 50, 50, 'close')
      .setInteractive({ useHandCursor: true })
      .setDepth(999);
    this.close.on('pointerover', () => this.close.setTexture('close_hover'));
    this.close.on('pointerout', () => this.close.setTexture('close'));
    this.close.on('pointerup', () => {
      this.close.setTexture('close_hover');
      this.scene.start('StartScreen');
    });

    this.add.text(
      width / 2 - 50,
      height - 50,
      this.address.slice(0, 4) + '..' + this.address.slice(-4),
      this.font
    );

    EventBus.emit('current-scene-ready', this);
  }
}
