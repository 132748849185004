import { Scene } from 'phaser';

// LEVEL 1
import player from '../assets/player2.png';
import player_tank from '../assets/player_tank.png';
import player_runner from '../assets/player_runner.png';
import player_jump from '../assets/player_jump.png';
import ground from '../assets/b1_jungle_ground.png';
import obstacle1 from '../assets/dino-OBSTACLE-01.png';
import obstacle2 from '../assets/dino-OBSTACLE-02.png';
import b1_jungle_bg from '../assets/b1_jungle_bg.jpg';
import diamond from '../assets/diamond.png';
import obstacle_saw from '../assets/obstacle_saw.png';

import fg from '../assets/fg.png';
import mid from '../assets/mid.png';
import bg from '../assets/bg.png';

// START SCREEEN
import start_bg from '../assets/START/00-runner-ekran-START-bg.jpg';
import connect from '../assets/START/runner-ekran-START-connect-1.png';
import connect_hover from '../assets/START/runner-ekran-START-connect-2-hover.png';
import run from '../assets/START/runner-ekran-START-RUN.png';
import run_hover from '../assets/START/runner-ekran-START-RUN-hover.png';
import leaderboard from '../assets/START/runner-ekran-START-leaderboard-1.png';
import leaderboard_hover from '../assets/START/runner-ekran-START-leaderboard-2-hover.png';
import sound_on from '../assets/START/runner-ekran-START-sound-ON.png';
import sound_off from '../assets/START/runner-ekran-START-sound-ON.png';

// MAIN MENU
import main_menu_bg from '../assets/CHOOSE/screen-2-bg.jpg';
import dino_tank from '../assets/CHOOSE/dino-1-TANK-left.png';
import dino_default from '../assets/CHOOSE/dino-2-DEFAULT-mid.png';
import dino_runner from '../assets/CHOOSE/dino-3-RUNNER-right.png';

// GAME OVER
import game_over_bg from '../assets/GAME_OVER/runner-ekran-OVER-bg.jpg';
import run_again from '../assets/GAME_OVER/runner-ekran-OVER-button-1-AGAIN.png';
import run_again_hover from '../assets/GAME_OVER/runner-ekran-OVER-button-1-AGAIN-hover.png';
import go_leaderboard from '../assets/GAME_OVER/runner-ekran-OVER-button-2-LEADERBOARD.png';
import go_leaderboard_hover from '../assets/GAME_OVER/runner-ekran-OVER-button-2-LEADERBOARD-hover.png';

//LEADERBOARD
import leaderboard_bg from '../assets/LEADERBOARD/runner-ekran-LEADERBOARD-bg.jpg';
import close from '../assets/LEADERBOARD/runner-ekran-LEADERBOARD-X.png';
import close_hover from '../assets/LEADERBOARD/runner-ekran-LEADERBOARD-X-hover.png';

import russianharmony from '../assets/audio/russianharmony_4.mp3';

export class Preloader extends Scene {
  constructor() {
    super('Preloader');
  }

  init() {
    let { width, height } = this.sys.game.canvas;
    //  We loaded this image in our Boot Scene, so we can display it here
    this.add.image(width / 2, height / 2, 'loader');

    //  A simple progress bar. This is the outline of the bar.
    this.add
      .rectangle(width / 2, height / 2, 468, 32)
      .setStrokeStyle(1, 0xffffff);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle(
      width / 2 - 230,
      height / 2,
      4,
      28,
      0xffffff
    );

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {
      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + 460 * progress;
    });
  }

  preload() {
    //  spritesheets
    this.load.spritesheet('player_default', player, {
      frameWidth: 514,
      frameHeight: 258,
    });
    this.load.spritesheet('player_runner', player_runner, {
      frameWidth: 514,
      frameHeight: 258,
    });
    this.load.spritesheet('player_tank', player_tank, {
      frameWidth: 514,
      frameHeight: 258,
    });

    this.load.spritesheet('diamond', diamond, {
      frameWidth: 480,
      frameHeight: 420,
    });
    this.load.spritesheet('obstacle_saw', obstacle_saw, {
      frameWidth: 400,
      frameHeight: 400,
    });
    // this.load.spritesheet('jump', player_jump, {
    //   frameWidth: 514,
    //   frameHeight: 258,
    // });

    // LEVEL1
    this.load.image('ground', ground);
    this.load.image('obstacle1', obstacle1);
    this.load.image('obstacle2', obstacle2);
    this.load.image('b1_jungle_bg', b1_jungle_bg);

    this.load.image('fg', fg);
    this.load.image('mid', mid);
    this.load.image('bg', bg);

    //START SCREEN
    this.load.image('start_bg', start_bg);
    this.load.image('connect', connect);
    this.load.image('connect_hover', connect_hover);
    this.load.image('run', run);
    this.load.image('run_hover', run_hover);
    this.load.image('leaderboard', leaderboard);
    this.load.image('leaderboard_hover', leaderboard_hover);
    this.load.image('sound_on', sound_on);
    this.load.image('sound_off', sound_off);

    // MAIN MENU
    this.load.image('main_menu_bg', main_menu_bg);
    this.load.image('dino_tank', dino_tank);
    this.load.image('dino_default', dino_default);
    this.load.image('dino_runner', dino_runner);

    //GAME OVER
    this.load.image('game_over_bg', game_over_bg);
    this.load.image('run_again', run_again);
    this.load.image('run_again_hover', run_again_hover);
    this.load.image('go_leaderboard', go_leaderboard);
    this.load.image('go_leaderboard_hover', go_leaderboard_hover);

    //LEADERBOARD
    this.load.image('leaderboard_bg', leaderboard_bg);
    this.load.image('close', close);
    this.load.image('close_hover', close_hover);

    // sound
    this.load.audio('theme', [russianharmony]);
  }

  create() {
    //  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
    //  For example, you can define global animations here, so we can use them in other scenes.

    //  Move to the MainMenu. You could also swap this for a Scene Transition, such as a camera fade.
    // this.scene.start('GameOver', {
    //   dino: 'default',
    //   score: 23434234,
    //   time: '00h00m56s',
    // });
    this.scene.start('StartScreen');
  }
}
