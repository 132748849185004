import { EventBus } from '../EventBus';
import { Scene, Math as M } from 'phaser';

export class Level1 extends Scene {
  logoTween;

  constructor() {
    super('Level1');
  }

  init(data) {
    this.groundSpeed = 230;
    this.obstacleVelocity = 230;
    this.WebFont = window.WebFont;
    this.totalScore = 0;
    this.bonus = 0;
    this.playTime = 0;
    this.startTime = 0; // Variable to store the start time
    this.elapsedTime = 0;
    this.isPlayerOnGround = false;

    this.dino = data.dino;
  }

  jump() {
    if (this.isPlayerOnGround) {
      this.player.setVelocityY(-550);
      this.player.anims.play('jump');
      this.player.anims.chain('run');
      this.isPlayerOnGround = false;
    }
  }

  createPlayer() {
    this.run = {
      key: 'run',
      frames: this.anims.generateFrameNumbers(`player_${this.dino}`, {
        start: 0,
        end: 16,
      }),
      frameRate: 20,
      repeat: -1,
    };
    const jump = {
      key: 'jump',
      frames: this.anims.generateFrameNumbers(`player_${this.dino}`, {
        start: 17,
        end: 26,
      }),
      frameRate: 10,
      repeat: 0,
    };
    this.player = this.physics.add
      .sprite(200, this.sys.game.canvas.height - 280, `player_${this.dino}`)
      .setScale(0.6)
      .setDepth(999);

    this.player.body.setSize(55, 200, true);
    const offsetX = (this.player.width + 50) / 2; // Calculate X offset relative to sprite width
    const offsetY = (this.player.height - 150) / 2; // Calculate Y offset relative to sprite height
    this.player.body.setOffset(offsetX, offsetY); //
    this.player.anims.create(this.run);
    this.player.anims.create(jump);
    this.player.anims.play('run');

    this.player.setCollideWorldBounds(true);
  }

  createGround() {
    const width = this.sys.game.canvas.width;
    const height = this.sys.game.canvas.height;

    // Create a TileSprite to represent the ground
    this.ground = this.add
      .tileSprite(0, height - 41, width, 83, 'ground')
      .setDepth(5);

    // Set TileSprite properties
    this.ground.setOrigin(0.5, 0.5);
    this.ground.setTileScale(1.0, 1); // Adjust scale as needed
    // this.ground.setTilePosition(0, 0); // Initial position

    // Enable physics for the ground
    this.physics.world.enable(this.ground);

    // Set collide world bounds to true
    this.ground.body.setCollideWorldBounds(true);

    // Set velocity to move from right to left
    this.ground.tilePositionX = 0; // Set initial position
    // this.groundVelocity = -170; // Set velocity
  }

  createBackground() {
    const width = this.sys.game.canvas.width;
    const height = this.sys.game.canvas.height;

    // Create a TileSprite to represent the ground
    this.bg = this.add.tileSprite(540, height / 2, 1088, 810, 'b1_jungle_bg');

    // Set TileSprite properties
    // this.bg.setOrigin(0.5, 0.5);
    this.bg.setTileScale(0.5, 0.5); // Adjust scale as needed
    this.bg.setTilePosition(0, 0); // Initial position

    // Set velocity to move from right to left
    this.bg.tilePositionX = 0; // Set initial position
  }

  // createBackground() {
  //   const width = this.sys.game.canvas.width;
  //   const height = this.sys.game.canvas.height;

  //   // Create a TileSprite to represent the ground
  //   this.fg = this.add
  //     .tileSprite(540, height / 2, 1088, 810, 'fg')
  //     .setTileScale(0.5, 0.5)
  //     .setTilePosition(0, 0)
  //     .setDepth(3); // Initial position
  //   this.fg.tilePositionX = 0; // Set initial position

  //   // this.mid = this.add
  //   //   .tileSprite(540, height / 2, 1088, 810, 'mid')
  //   //   .setTileScale(0.5, 0.5)
  //   //   .setTilePosition(0, 0)
  //   //   .setDepth(2); // Initial position
  //   // this.mid.tilePositionX = 0; // Set initial position

  //   this.bg = this.add
  //     .tileSprite(540, height / 2, 1088, 810, 'bg')
  //     .setTileScale(0.5, 0.5)
  //     .setTilePosition(0, 0)
  //     .setDepth(1); // Initial position
  //   this.bg.tilePositionX = 0; // Set initial position
  // }

  createPointsCounter() {
    this.score = this.add.text(20, 20, '0', {
      fontFamily: 'Arial',
      fontSize: 40,
      color: '#fff',
    });
    this.score.setDepth(999);
  }

  spawnObstacles() {
    const rotate = {
      key: 'rotate',
      frames: this.anims.generateFrameNumbers('obstacle_saw', {
        start: 1,
        end: 4,
      }),
      frameRate: 4,
      repeat: -1,
    };
    const randomY = M.Between(170, 261);
    const saw = this.obstacles
      .create(
        this.sys.game.canvas.width + 100,
        this.sys.game.canvas.height - 115,
        `obstacle_saw`
      )
      .setDepth(6); // Create diamond
    saw.body.setAllowGravity(false);
    saw.anims.create(rotate);
    saw.anims.play('rotate');

    saw.body.setCircle(saw.displayWidth / 6.5);
    saw.setOrigin(0.5);
    saw.setScale(0.5);
    saw.body.setOffset(140, 140); //

    saw.setVelocityX(this.groundSpeed * -1); // Set velocity to move from right to left
    const randomDelay = M.Between(1500, 4000); // Random delay between 1 and 5 seconds

    this.time.delayedCall(randomDelay, this.spawnObstacles, [], this); // Schedule next spawn
  }

  spawnDiamonds() {
    const flicker = {
      key: 'f',
      frames: this.anims.generateFrameNumbers('diamond', {
        start: 0,
        end: 3,
      }),
      frameRate: 4,
      repeat: -1,
    };
    const randomY = M.Between(170, 261);
    const diamond = this.diamonds.create(
      this.sys.game.canvas.width + 100,
      this.sys.game.canvas.height - randomY,
      `diamond`
    ); // Create diamond
    diamond.body.setAllowGravity(false);
    diamond.anims.create(flicker);
    diamond.anims.play('f');
    diamond.setScale(0.1);
    // Get the alpha data of the sprite's texture
    diamond.setOrigin(0.5);

    diamond.body.setCircle(diamond.displayWidth * 4.5);
    diamond.setVelocityX(this.groundSpeed * -1); // Set velocity to move from right to left
    const randomDelay = M.Between(2500, 8000); // Random delay between 1 and 5 seconds

    this.time.delayedCall(randomDelay, this.spawnDiamonds, [], this); // Schedule next spawn
  }

  playerHitObstacle(player, obstacle) {
    obstacle.destroy();
    const currentVelocityX = player.body.velocity.x;
    // player.setAccelerationX(-currentVelocityX * 2);
    this.scene.start('GameOver', {
      score: this.totalScore,
      time: this.playTime,
      dino: this.dino,
    });
    EventBus.emit('game-over', {
      score: this.totalScore,
      time: this.playTime,
      dino: this.dino,
    });
  }
  playerCollectDiamond(player, diamond) {
    diamond.destroy();
    this.bonus += 100;
  }

  create() {
    this.startTime = this.time.now;
    const music = this.sound.add('theme', { volume: 0.5 });
    // music.play();

    let { width, height } = this.sys.game.canvas;
    this.createPointsCounter();

    this.createPlayer();
    this.createBackground();
    this.createGround();
    this.obstacles = this.physics.add.group({
      gravity: { y: 0 },
    });
    this.spawnObstacles();
    this.diamonds = this.physics.add.group({
      gravity: { y: 0 },
    });
    this.spawnDiamonds();

    this.physics.add.collider(this.player, this.ground);
    // Enable collision between player and obstacles
    this.physics.add.overlap(
      this.player,
      this.obstacles,
      this.playerHitObstacle,
      null,
      this
    );
    this.physics.add.overlap(
      this.player,
      this.diamonds,
      this.playerCollectDiamond,
      null,
      this
    );

    // this.physics.add.collider(this.obstacles, this.ground);

    EventBus.emit('current-scene-ready', this);
  }
  update(time) {
    this.elapsedTime = this.time.now - this.startTime;
    //  Increase game speed by 0.1 every 10 seconds
    this.gameSpeed = 1 + this.elapsedTime / 10000;

    if (this.player.body.touching.down) {
      this.isPlayerOnGround = true;
    }
    this.input.keyboard.on(
      'keydown-SPACE',
      function (event) {
        this.jump();
      },
      this
    );

    // Move obstacles
    this.obstacles.getChildren().forEach((obstacle) => {
      //   obstacle.x -= 3; // Adjust the speed of obstacles as needed
      if (obstacle.x < -obstacle.displayWidth) {
        obstacle.destroy(); // Destroy obstacles that are out of the screen
      } else {
        this.obstacleVelocity +=
          (0.003 * this.game.loop.delta) / 100000 + this.elapsedTime / 100000;
        obstacle.body.velocity.x = -this.obstacleVelocity;
      }
    });
    // Move diamonds
    this.diamonds.getChildren().forEach((diamond) => {
      //   obstacle.x -= 3; // Adjust the speed of obstacles as needed
      if (diamond.x < -diamond.displayWidth) {
        diamond.destroy(); // Destroy obstacles that are out of the screen
      }
    });

    // Adjust ground speed based on elapsed time
    // this.groundSpeed = 0.1 + this.elapsedTime / 1000; // Example: Increase ground speed by 0.01 every 10 seconds
    // Update the tile position based on the adjusted ground speed
    this.ground.tilePositionX +=
      (this.groundSpeed * this.game.loop.delta) / 2000 +
      this.elapsedTime / 10000;

    // move background
    this.bg.tilePositionX +=
      (this.groundSpeed * this.game.loop.delta) / 1000 +
      this.elapsedTime / 10000; // Adjust velocity based on frame rate

    this.run.frameRate +=
      (this.groundSpeed * this.game.loop.delta) / 1000 +
      this.elapsedTime / 10000;

    // this.mid.tilePositionX +=
    //   (this.groundSpeed * this.game.loop.delta) / 1500 +
    //   this.elapsedTime / 10000; // Adjust velocity based on frame rate

    // this.fg.tilePositionX +=
    //   (this.groundSpeed * this.game.loop.delta) / 2000 +
    //   this.elapsedTime / 10000; // Adjust velocity based on frame rate

    this.totalScore = parseInt(this.elapsedTime / 100) + this.bonus;
    this.score.setText(this.totalScore);

    // Update the text to display the elapsed time
    const seconds = Math.floor(this.elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    this.playTime = `${hours < 10 ? '0' + hours : hours}:${
      minutes % 60 < 10 ? '0' + (minutes % 60) : minutes % 60
    }:${seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60}`;

    // Set the delta time scale to adjust game speed
    // this.time.timeScale = this.gameSpeed;
  }
}
