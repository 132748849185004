import { EventBus } from '../EventBus';
import { Scene } from 'phaser';

export class MainMenu extends Scene {
  logoTween;

  constructor() {
    super('MainMenu');
  }
  init(data) {
    this.address = data.address;
    this.dinos = [];
  }
  hover_in(index) {
    this.dinos[index].setScale(1.01);
    this.dinos[index].setAlpha(1);
    this.dinos.forEach((item, idx) => {
      idx !== index && item.setAlpha(0.4);
    });
  }
  hover_out(index) {
    this.dinos[index].setScale(1);
    this.dinos[index].setAlpha(0.4);
    this.dinos.forEach((item, idx) => {
      idx !== index && item.setAlpha(0.4);
    });
    this.dinos[1].setAlpha(1);
  }

  create() {
    let { width, height } = this.sys.game.canvas;
    this.add.image(width / 2, height / 2, 'main_menu_bg');

    // TANK
    this.tank = this.add
      .image(width / 2 - 330, height / 2 + 80, 'dino_tank')
      .setInteractive({ useHandCursor: true })
      .setOrigin(0.5)
      .setAlpha(0.4);
    this.tank.on('pointerover', () => this.hover_in(0));
    this.tank.on('pointerout', () => this.hover_out(0));
    this.tank.on('pointerup', () => {
      this.hover_in(0);
      this.scene.start('Level1', { dino: 'tank' });
    });
    this.dinos.push(this.tank);

    this.default = this.add
      .image(width / 2, height / 2, 'dino_default')
      .setInteractive({ useHandCursor: true })
      .setOrigin(0.5)
      .setAlpha(1);
    this.default.on('pointerover', () => this.hover_in(1));
    this.default.on('pointerout', () => this.hover_out(1));
    this.default.on('pointerup', () => {
      this.hover_in(1);
      this.scene.start('Level1', { dino: 'default' });
    });
    this.dinos.push(this.default);

    this.runner = this.add
      .image(width / 2 + 330, height / 2 + 80, 'dino_runner')
      .setInteractive({ useHandCursor: true })
      .setOrigin(0.5)
      .setAlpha(0.4);
    this.runner.on('pointerover', () => this.hover_in(2));
    this.runner.on('pointerout', () => this.hover_out(2));
    this.runner.on('pointerup', () => {
      this.hover_in(2);
      this.scene.start('Level1', { dino: 'runner' });
    });
    this.dinos.push(this.runner);

    EventBus.emit('current-scene-ready', this);
  }
}
